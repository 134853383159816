<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Fresar',
    titleTemplate: '%a | EAL by John TMarz',
    htmlAttrs: { lang: 'es' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ],
  },
};
</script>

<style lang="scss">
  $main-font: 'Lato', sans-serif;
  $primary-color: #F35E22;
  $secondary-color: #595F63;
  $dark-gray: #2D333B;

  body {
    #app {
    }
  }
</style>