import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import '../styles/variables.scss';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#F35E22', //orange
          secondary: '#595F63', //gray
          accent: '#2D333B', //dark-gray
          error: '#f05454',
          success: '#F35E22' //orange
          
        }
      }
    },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'md',
  },
});
